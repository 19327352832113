import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { ApiService } from '@app/core/services/http/api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  env = environment;
  /*baseUrl = this.env.api.users;*/

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Get a listing of the resource.
   */
  /*getAll() {
    return this.apiService.get(this.baseUrl);
  }*/

  /**
   * Get the logged in user.
   */
  /*loggedIn() {
    return this.apiService.get(this.baseUrl + '/loggedin');
  }*/


  /**
   * Get the specified resource.
   */
  /*getItem(id: number) {
    return this.apiService.get(this.baseUrl + '/' + id);
  }*/

  /**
   * Store a newly created resource in storage.
   * @param data
   */
  /*post(data: object) {
    return this.apiService.post(this.baseUrl, data);
  }*/

  /**
   * Update the specified resource in storage.
   */
  /*put(data: object) {
    return this.apiService.put(this.baseUrl, data);
  }*/

  /**
   * Remove the specified resource from storage.
   * @param id
   */
  /*delete(id: number) {
    return this.apiService.delete(this.baseUrl + '/' + id);
  }*/
}
