import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryHelperService {

  constructor() { }

  string(params: {}){
    const queryString = Object.keys(params).map(function (key) {
      if(params[key] != ''){
        return key + '=' + params[key];
      }
     
    }).join('&');
    return queryString;
  }
}
